import { graphql } from "gatsby";
import * as React from "react";
import Layout from "../../../components/layout/layout";
import PageContent from "../../../components/layout/content";
import PageHeader from "../../../components/layout/header/pageHeader";
import { useMemo } from "react";
import useLayoutGeneration from "../../../core/hooks/useLayoutGeneration";
import "../../../theme/style.scss";

const InstructorsIndexPage = ({ data }) => {
  const categoryData = useMemo(() => data.contentfulOldalKategoria, [data]);
  const contents = useMemo(
    () => data?.allContentfulOldal?.edges?.map((edge) => edge.node),
    [data],
  );
  const [generator, resolver] = useLayoutGeneration();

  const generatedContent = useMemo(() => {
    const contentWithWrapper = {
      internal: { type: "generalInstructorsTartalom" },
      title: "Lorem ipsum dolor sit amet",
      ComponentContent: contents.map((content) => {
        return {
          ...content,
          internal: {
            type: content?.tartalomTpus?.internal?.type,
          },
          ctaLink: `/${categoryData?.slug}/${content?.slug}`,
          ctaText: "More information",
        };
      }),
    };
    const generatedList = generator([contentWithWrapper]);
    const componentList = generatedList.map(resolver);
    return componentList;
  }, [contents, generator, resolver, categoryData]);

  return (
    <Layout>
      <PageHeader pageData={categoryData} />
      <PageContent>{generatedContent}</PageContent>
    </Layout>
  );
};
export default InstructorsIndexPage;
//the query gets the category name

//contenfulId is the category contentful_id
export const query = graphql`
  query general($contentfulId: String) {
    contentfulOldalKategoria(contentful_id: { eq: $contentfulId }) {
      cm: kategria
      slug
      alcm: rvidlers
      header {
        bortkp {
          gatsbyImageData(layout: CONSTRAINED)
          url
        }
        lers
        title
        primaryCta {
          ... on ContentfulCtaGomb {
            ctaGombLink {
              ... on ContentfulOldal {
                slug
                kategria {
                  slug
                }
              }
              ... on ContentfulOldalKategoria {
                slug
              }
            }
            ctaGombSzveg
            externalCtaGombLink
          }
        }
        secondaryCta {
          ... on ContentfulCtaGomb {
            ctaGombLink {
              ... on ContentfulOldal {
                slug
                kategria {
                  slug
                }
              }
              ... on ContentfulOldalKategoria {
                slug
              }
            }
            ctaGombSzveg
            externalCtaGombLink
          }
        }
      }
    }
    allContentfulOldal(
      filter: { kategria: { contentful_id: { eq: $contentfulId } } }
    ) {
      edges {
        node {
          slug
          cm
          alcm
          sszest {
            sszest
          }
          tartalomTpus {
            ... on ContentfulElerhetosegeinkTartalomTipus {
              header {
                bortkp {
                  gatsbyImageData(layout: CONSTRAINED)
                  url
                }
              }
              internal {
                type
              }
            }
            ... on ContentfulInstructorsTartalomTipus {
              header {
                bortkp {
                  gatsbyImageData(layout: CONSTRAINED)
                }
              }
              services {
                ikon {
                  gatsbyImageData(layout: CONSTRAINED)
                }
              }
              whatsapp
              messenger
              phoneNumber
              email
              internal {
                type
              }
            }
            __typename
          }
        }
      }
    }
  }
`;
